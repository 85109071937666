<template>
  <v-card outlined color="#F2F4F7">
    <FlightAlert
      @searchCity="searchCity"
      :link-text="linkTextAlternative"
      ref="FlightAlert"
    />

    <v-container
      class="dining-container py-0 px-4"
      :class="[
        { 'pb-5': mapisOpen && $vuetify.breakpoint.mdAndUp },
        { 'px-sm-10': $vuetify.breakpoint.mdAndUp && !mapisOpen },
      ]"
    >
      <template v-if="!isMobile">
        <v-row no-gutters>
          <v-col cols="12">
            <h1 class="header-title pb-5 pt-10">
              {{ $t('dining.find_restaurant') }}
            </h1>
          </v-col>
        </v-row>
      </template>

      <v-row align="center" no-gutters :justify="isMobile ? 'center' : 'start'">
        <template v-if="isMobile && !mapisOpen">
          <v-col cols="auto" class="px-2">
            <v-img
              :alt="$t('dining.home_navigation_icon')"
              @click="goToHomepage"
              class="nav-icon ma-auto"
              width="24"
              height="24"
              src="@/assets/nav-icon.svg"
            />
          </v-col>
        </template>

        <v-col :cols="setColsBreackpoints" :class="{ 'py-0': isMobile }">
          <template v-if="isMobile">
            <v-container
              class="search-trigger pa-0 py-2"
              @click="$emit('click')"
              id="searchResultTextMobile"
            >
              <v-row no-gutters>
                <v-col cols="12">
                  <v-text-field
                    :value="searchResultText"
                    disabled
                    outlined
                    rounded
                    dense
                    label=""
                    aria-labelledby="searchResultTextMobile"
                    aria-selected="false"
                    tabindex="0"
                  >
                    <template v-slot:append>
                      <v-img
                        :alt="$t('dining.search_button')"
                        class="nav-icon ma-auto"
                        width="36"
                        height="36"
                        src="@/assets/search-button.svg"
                      />
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </template>

          <template v-if="!isMobile && mapisOpen">
            <v-container class="search-trigger pa-0 py-2">
              <v-row no-gutters>
                <v-col cols="12">
                  <v-menu
                    ref="menu"
                    role="button"
                    max-width="516"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    v-model="menu"
                    eager
                  >
                    <template v-slot:activator="{ on }">
                      <div
                        v-on="on"
                        class="trigger"
                        id="searchResultTextDesktop"
                      >
                        <v-text-field
                          :value="searchResultText"
                          autocomplete="off"
                          disabled
                          outlined
                          rounded
                          dense
                          placeholder="Search"
                          label=""
                          aria-labelledby="searchResultTextDesktop"
                          aria-selected="false"
                          tabindex="0"
                        >
                          <template v-slot:append>
                            <v-img
                              :alt="$t('dining.search_button')"
                              class="nav-icon ma-auto"
                              width="36"
                              height="36"
                              src="@/assets/search-button.svg"
                            />
                          </template>
                        </v-text-field>
                      </div>
                    </template>
                    <v-card class="search-dropdown">
                      <SearchComponent
                        @close="menu = false"
                        :mapState="mapisOpen"
                        ref="SearchComponentMap"
                        @showAlert="getAlert"
                      />
                    </v-card>
                  </v-menu>
                </v-col>
              </v-row>
            </v-container>
          </template>

          <template v-if="!isMobile && !mapisOpen">
            <SearchComponent ref="SearchComponent" @showAlert="getAlert" />
          </template>
        </v-col>

        <template v-if="displayMapTrigger">
          <v-col class="map-cta">
            <v-chip
              @click="openMap"
              class="mt-md-n4 px-4 map-cta-trigger"
              color="#CFD5E0"
              outlined
            >
              <v-icon left class="icon"> mdi-map </v-icon>
              <span class="map-cta-text"> {{ $t('dining.show_map') }}</span>
            </v-chip>
          </v-col>
        </template>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import SearchComponent from './SearchComponent';
import DiningMixins from '../../mixins/DiningMixins.vue';
import FlightAlert from '@/core/components/FlightAlert';
import SelectedContextFlightMixins from '@/modules/flights/mixins/SelectedContextFlightMixins';

export default {
  name: 'SearchHeaderComponent',
  components: { SearchComponent, FlightAlert },
  mixins: [DiningMixins, SelectedContextFlightMixins],
  props: {
    isMobile: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      mapisOpen: false,
      menu: false,
    };
  },

  methods: {
    goToHomepage() {
      if (this.selectedContextFlight) {
        this.$router.push({ name: 'concierge' });
      } else {
        this.$router.push({ name: 'concierge-discover' });
      }
    },

    openMap() {
      this.$router.push({
        name: 'dining-map',
        params: this.$route.params,
        query: {
          q: this.$route.query.q,
          city: this.$route.query.city,
          lng: this.$route.query.lng,
          lat: this.$route.query.lat,
        },
      });
    },

    searchCity() {
      if (this.mapisOpen) {
        this.$refs.SearchComponentMap.autoSearch();
      } else {
        this.$refs.SearchComponent.autoSearch();
      }
    },

    getAlert() {
      this.$refs.FlightAlert.showAlert();
    },
  },

  computed: {
    setColsBreackpoints() {
      let cols = 'auto';
      if (this.mapisOpen) {
        cols = '12';
      } else if (this.$vuetify.breakpoint.smOnly) {
        cols = '10';
      } else if (this.$vuetify.breakpoint.mdOnly) {
        cols = '9';
      } else if (this.$vuetify.breakpoint.lgOnly) {
        cols = '10';
      } else if (this.$vuetify.breakpoint.xlOnly) {
        cols = 'auto';
      } else if (this.$vuetify.breakpoint.xsOnly) {
        cols = '10';
      }
      return cols;
    },

    searchResultText() {
      if (this.$route.query.city) {
        if (this.$route.query.q) {
          return this.$t('dining.search_result_text', {
            keyword: this.$route.query.q,
            city: this.$route.query.city,
          });
        }
        return this.$t('dining.search_results_title', {
          city: this.$route.query.city,
        });
      } else if (this.$route.query.q) {
        return `${this.$route.query.q}`;
      } else return '';
    },

    displayMapTrigger() {
      return (
        this.$vuetify.breakpoint.mdAndUp &&
        !this.mapisOpen &&
        this.hasDiningSearch
      );
    },

    linkTextAlternative() {
      return this.$t('product.search_in_city', {
        city: this.selectedContextFlight?.destination.city,
      });
    },
  },

  watch: {
    $route: {
      immediate: true,
      handler(val) {
        if (!!val && val.name == 'dining-map' && this.hasDiningSearch) {
          this.mapisOpen = true;
        }
        if (!!val && val.name == 'dining-results') {
          this.mapisOpen = false;
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.dining-container {
  max-width: 1208px;
}

.nav-icon {
  cursor: pointer;
}

.nav-text {
  color: var(--v-grey7-base);
  @include font-size(14, 150, 600);
}

.header-title {
  color: #05090f;
  @include font-size(30, 150);
}

::v-deep
  .search-trigger
  .v-text-field.v-text-field--enclosed
  .v-text-field__details {
  display: none !important;
}
::v-deep .search-trigger .v-input__slot {
  margin: 0;
}
::v-deep .search-trigger fieldset {
  background: #fff;
  border: 1px solid #cfd5e0;
}
::v-deep
  .search-trigger
  .v-text-field--rounded
  > .v-input__control
  > .v-input__slot {
  padding: 0 6px;
}

::v-deep .search-trigger .v-input__append-inner {
  margin-bottom: 4px;
  margin-top: 4px !important;
}

::v-deep .v-text-field__slot input {
  @include font-size(16, 150);
  display: flex;
  align-items: center;
  color: #05090f;
}

::v-deep
  .search-trigger
  .v-text-field--rounded
  > .v-input__control
  > .v-input__slot {
  padding: 0 4px 0 16px;
}
.map-cta {
  text-align: center;

  @media (min-width: map-get($grid-breakpoints, md)) {
    text-align: right;
  }

  &-text {
    @include font-size(14, 150);
    color: #05090f;
  }

  &-trigger {
    cursor: pointer;
    padding: 8px 16px;
    border-radius: 26px;
    height: 44px;
  }

  &-icon {
    color: var(--v-grey7-base) !important;
  }
}

.search-dropdown {
  width: 100%;
  border-radius: 8px;
}

.trigger {
  cursor: pointer;
}

.breadcrumbs {
  &-first {
    color: var(--v-primary-darken1);
  }

  &-second {
    color: var(--v-grey7-base);
  }
}
</style>
