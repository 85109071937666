<template>
  <v-dialog fullscreen hide-overlay :value="dialog">
    <v-sheet :elevation="0" color="white">
      <v-container class="px-5 py-8">
        <v-row no-gutters class="pb-6" justify="space-between">
          <v-col cols="12" class="d-flex justify-space-between">
            <span class="dialog-title">
              {{ $t('dining.find_restaurant') }}
            </span>
            <span @click="onClose">
              <v-icon> mdi-close </v-icon>
            </span>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12">
            <SearchComponent
              @modalClose="onClose"
              :preSelectedSearch="preSelectedSearch"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-sheet>
  </v-dialog>
</template>

<script>
import SearchComponent from './SearchComponent.vue';

export default {
  name: 'SearchModalComponent',
  components: { SearchComponent },
  props: {
    modalView: {
      type: Boolean,
      default: false,
    },
    preSelectedSearch: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      dialog: this.modalView,
    };
  },

  methods: {
    onClose() {
      this.$emit('close');
      this.dialog = false;
    },
  },

  watch: {
    modalView(val) {
      this.dialog = val;
    },
  },
};
</script>

<style lang="scss" scoped>
.dialog-title {
  letter-spacing: 0.25px;
  @include font-size(20, 150, 600);
  color: #05090f;
}

::v-deep .v-dialog {
  background: #fff;
}
</style>
