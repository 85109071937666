<template>
  <v-container fluid :class="['pa-0', { fixed: scrollLocked }]">
    <v-row no-gutters>
      <v-col
        :cols="displayMap && $vuetify.breakpoint.lgAndUp ? 4 : 12"
        class="pt-0"
      >
        <SearchHeaderComponent
          :isMobile="$vuetify.breakpoint.mdAndDown"
          @click="toggleModal"
          @open="showMap"
        />
        <SearchModalComponent
          :preSelectedSearch="diningSearch.city"
          :modalView="modalState"
          @close="toggleModal"
        />
        <v-container fluid v-if="!fullScreenMap" class="pt-0">
          <router-view name="results"></router-view>
          <router-view name="results-map"></router-view>
        </v-container>
      </v-col>

      <v-col
        :cols="fullScreenMap ? 12 : 8"
        class="map-view"
        :class="{ fixed: displayMap }"
      >
        <router-view name="map"></router-view>
      </v-col>
    </v-row>

    <template v-if="displayMapTrigger && !fullScreenMap">
      <v-row class="trigger-wrapper" no-gutters>
        <v-col cols="12" class="d-flex justify-center">
          <span class="py-2 px-3 map-trigger" @click="showMap">
            <v-icon class="icon mr-1"> mdi-map </v-icon>
            {{ $t('dining.map_view') }}
          </span>
        </v-col>
      </v-row>
    </template>
  </v-container>
</template>

<script>
import SearchHeaderComponent from './features/Search/SearchHeaderComponent';
import SearchModalComponent from './features/Search/SearchModalComponent';
import DiningMixins from './mixins/DiningMixins';

export default {
  name: 'DiningLandingPage',
  components: { SearchHeaderComponent, SearchModalComponent },
  mixins: [DiningMixins],

  data() {
    return {
      modalState: false,
      displayMap: false,
      fullScreenMap: false,
    };
  },

  methods: {
    toggleModal() {
      this.modalState = !this.modalState;
    },

    showMap() {
      this.$router.push({
        name: 'dining-map',
        params: this.$route.params,
        query: {
          q: this.$route.query.q,
          city: this.$route.query.city,
          lng: this.$route.query.lng,
          lat: this.$route.query.lat,
        },
      });
    },

    updateCenter(newCenterCoordinates) {
      this.updateCenterCoordinates(newCenterCoordinates);
    },

    updateSearchContent(newValues) {
      const {
        lng,
        lat,
        tags,
        minRating,
        minPriceScale,
        maxPriceScale,
        radius,
      } = this.diningSearch;
      this.searchDining({
        city: this.$route.query.city,
        lng: newValues.lng,
        lat: newValues.lat,
        tags,
        minRating,
        minPriceScale,
        maxPriceScale,
        radius,
      });
    },

    redirectToErrorPage() {
      const notFoundError =
        this.diningSearchError && this.diningSearchError.status >= 400;
      const internalServerError =
        this.diningSearchError && this.diningSearchError.status >= 500;

      if (internalServerError) this.$router.replace('/500');
      else if (notFoundError) this.$router.replace('/404');
    },
  },

  computed: {
    searchCoordinates() {
      const { lat, lng } = this.diningSearch;
      return [lat, lng];
    },

    foundLocations() {
      return this.diningSearch &&
        this.diningSearch.items &&
        this.diningSearch.items.length
        ? this.diningSearch.items
        : [];
    },

    displayMapTrigger() {
      return this.$vuetify.breakpoint.mdAndDown && this.hasDiningSearch;
    },

    scrollLocked() {
      return this.$vuetify.breakpoint.mdAndDown && this.displayMap;
    },
  },

  watch: {
    updatedCenterCoordinates: {
      deep: true,
      handler(val) {
        if (!!val) {
          this.updateSearchContent(val);
          this.searchUpdate = false;
        }
      },
    },

    diningSearchError(val) {
      if (!!val) {
        this.redirectToErrorPage();
      }
    },

    hasDiningSearch(val) {
      if (!val) {
        this.displayMap = false;
      }
    },

    $route: {
      immediate: true,
      handler(val, oldVal) {
        if (val && val.name == 'dining-map' && !!this.hasDiningSearch) {
          this.displayMap = true;
          if (this.$vuetify.breakpoint.mdAndDown) {
            this.fullScreenMap = true;
          }
        }
        if (val && val.name == 'dining-results') {
          if (this.$vuetify.breakpoint.mdAndDown) {
            this.fullScreenMap = false;
          }
          if (oldVal && oldVal.name == 'dining-map') {
            this.displayMap = false;
          }
        }
      },
    },

    '$vuetify.breakpoint.mdAndDown'(val) {
      this.fullScreenMap = val;
    },
  },
};
</script>

<style scoped lang="scss">
.map-trigger {
  position: fixed;
  z-index: 100;
  background: #fff;
  border: 1px solid #cfd5e0;
  box-sizing: border-box;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12),
    0px 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  color: #000;
  bottom: 20px;
  @include font-size(14, 150, 600);
}

.trigger-wrapper {
  cursor: pointer;
}

.map-view {
  height: 100%;
  top: 0;
  z-index: 1;
  position: sticky;
  right: 0;
  overflow-x: hidden;
}

.fixed {
  position: fixed;
}
</style>
