<template>
  <v-container fluid class="pa-0">
    <v-row
      :class="[
        'pa-0',
        $vuetify.breakpoint.smAndDown || map
          ? 'search-box-mobile'
          : 'search-box',
      ]"
      no-gutters
      justify="start"
      id="KeywordSearchComponent"
    >
      <v-col>
        <v-text-field
          height="44"
          outlined
          hide-details
          single-line
          full-width
          required
          dense
          clearable
          @keydown.enter.prevent="$emit('submit', queryModel)"
          @blur="onBlur"
          @click:clear="onClear"
          :placeholder="$t('dining.placeholder')"
          v-model="queryModel"
          class="search-field"
          label=""
          aria-labelledby="KeywordSearchComponent"
          aria-selected="false"
          tabindex="0"
        >
          <template v-slot:prepend-inner>
            <svg
              class="mt-1"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M17.5 17.5L12.5 12.5L17.5 17.5ZM14.1667 8.33333C14.1667 9.09938 14.0158 9.85792 13.7226 10.5657C13.4295 11.2734 12.9998 11.9164 12.4581 12.4581C11.9164 12.9998 11.2734 13.4295 10.5657 13.7226C9.85792 14.0158 9.09938 14.1667 8.33333 14.1667C7.56729 14.1667 6.80875 14.0158 6.10101 13.7226C5.39328 13.4295 4.75022 12.9998 4.20854 12.4581C3.66687 11.9164 3.23719 11.2734 2.94404 10.5657C2.65088 9.85792 2.5 9.09938 2.5 8.33333C2.5 6.78624 3.11458 5.30251 4.20854 4.20854C5.30251 3.11458 6.78624 2.5 8.33333 2.5C9.88043 2.5 11.3642 3.11458 12.4581 4.20854C13.5521 5.30251 14.1667 6.78624 14.1667 8.33333Z"
                stroke="var(--v-primary-base)"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </template>
        </v-text-field>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'KeywordSearchComponent',
  props: {
    queryKeyword: {
      type: String,
      default: '',
    },
    map: Boolean,
  },

  data() {
    return {
      queryModel: this.queryKeyword,
    };
  },

  methods: {
    onClear() {
      if (this.$route.query.q?.length > 0) {
        this.$emit('onClear');
      }
    },

    onBlur(e) {
      this.$emit('formChange', e.target.value);
    },
  },

  watch: {
    queryKeyword(value) {
      this.queryModel = value;
    },
  },
};
</script>

<style lang="scss" scoped>
.search-box {
  width: 100%;
  height: 44px;
  border-radius: 8px;

  @media (min-width: map-get($grid-breakpoints, lg)) {
    width: 400px;
  }

  ::v-deep .v-input__append-inner {
    margin-top: 10px !important;
  }

  &-mobile {
    width: 100%;
    height: 44px;
    border-radius: 8px;
  }
}
::v-deep .v-input {
  background: white;
}
</style>
