<template>
  <v-container fluid class="pa-0" :class="{ 'px-5 py-6': mapState }">
    <v-form @submit.prevent="onSubmit" action="." ref="form">
      <v-row
        no-gutters
        :justify="
          $vuetify.breakpoint.mdAndDown || mapState ? 'center' : 'space-between'
        "
      >
        <v-col v-if="$vuetify.breakpoint.smAndDown" cols="12" class="py-0">
          <p class="mb-0">{{ $t('keywords') }}</p>
        </v-col>
        <v-col
          :class="[
            'pb-4',
            {
              'pr-3': $vuetify.breakpoint.lgAndUp && !mapState,
              'pt-0': $vuetify.breakpoint.smAndDown,
            },
          ]"
          :cols="setColsBreackpoints"
        >
          <KeywordSearchComponent
            :map="mapState"
            :queryKeyword="searchQuery"
            @formChange="onFormChange"
            @submit="onEnter"
            @onClear="onClearWord"
          />
        </v-col>
        <v-col
          v-if="$vuetify.breakpoint.smAndDown || mapState"
          cols="12"
          class="pb-0 pt-4"
        >
          <p class="mb-0">{{ $t('location_text') }}</p>
        </v-col>
        <v-col
          :class="[
            'pb-4',
            {
              'pr-3': $vuetify.breakpoint.lgAndUp && !mapState,
              'pt-0': $vuetify.breakpoint.smAndDown,
            },
          ]"
          :cols="setColsBreackpoints"
          id="SearchCity"
        >
          <v-combobox
            height="44"
            class="pb-0 search-field"
            :class="[isMobile || mapState ? 'search-box-mobile' : 'search-box']"
            dense
            flat
            solo
            outlined
            clearable
            :items="items"
            :value="selectedCity"
            no-filter
            :loading="citySearch.isLoading"
            :search-input.sync="search"
            @change="onChange"
            @keydown.enter.prevent="onSubmit"
            @click:clear="onClearCity"
            color="secondary"
            append-icon=""
            :placeholder="
              isMobile
                ? $t('product.search_city')
                : $t('product.where_do_you_want_eat')
            "
            return-object
            :no-data-text="$t('product.no_cities_found')"
            aria-labelledby="SearchCity"
            aria-selected="false"
            tabindex="0"
            hide-details
          >
            <template v-slot:prepend-inner>
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14.7143 13.8806L11.1785 17.4164C11.0239 17.5712 10.8402 17.694 10.6381 17.7778C10.436 17.8616 10.2194 17.9047 10.0006 17.9047C9.78179 17.9047 9.56515 17.8616 9.36303 17.7778C9.16092 17.694 8.9773 17.5712 8.82267 17.4164L5.286 13.8806C4.35368 12.9482 3.71878 11.7603 3.46157 10.4671C3.20437 9.17394 3.33641 7.83352 3.841 6.61536C4.3456 5.39721 5.20008 4.35604 6.2964 3.62351C7.39272 2.89098 8.68164 2.5 10.0002 2.5C11.3187 2.5 12.6076 2.89098 13.7039 3.62351C14.8003 4.35604 15.6547 5.39721 16.1593 6.61536C16.6639 7.83352 16.796 9.17394 16.5388 10.4671C16.2816 11.7603 15.6466 12.9482 14.7143 13.8806V13.8806Z"
                  stroke="var(--v-primary-base)"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M12.5 9.16602C12.5 9.82906 12.2366 10.4649 11.7678 10.9338C11.2989 11.4026 10.663 11.666 10 11.666C9.33696 11.666 8.70107 11.4026 8.23223 10.9338C7.76339 10.4649 7.5 9.82906 7.5 9.16602C7.5 8.50297 7.76339 7.86709 8.23223 7.39825C8.70107 6.92941 9.33696 6.66602 10 6.66602C10.663 6.66602 11.2989 6.92941 11.7678 7.39825C12.2366 7.86709 12.5 8.50297 12.5 9.16602V9.16602Z"
                  stroke="var(--v-primary-base)"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </template>
            <template v-slot:prepend-item>
              <v-list-item
                class="item-text"
                ripple
                @click="getUserLocationAndRedirect"
              >
                <v-list-item-action>
                  <v-icon color="secondary">mdi-near-me</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title class="item-text">
                    {{ $t('current_location') }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
            <template slot="item" slot-scope="{ item }">
              <v-list-item-action class="mr-2">
                <v-icon color="secondary">mdi-map-marker-outline</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title class="item-text"
                  >{{ item.text }}
                </v-list-item-title>
              </v-list-item-content>
            </template>
          </v-combobox>
          <div v-if="shouldShowTooltip" class="search-box-location-tooltip">
            {{
              $t('discovery.geo_tell_us_where_you_going', {
                cityName: userLocation.city,
              })
            }}
            <img
              class="search-box-location-tooltip-image"
              src="@/assets/close.svg"
              alt="close"
              @click="isOpenSearchTooltip = false"
            />
          </div>
        </v-col>
        <v-col v-if="!mapState" cols="auto" class="text-capitalize">
          <v-btn
            color="primary"
            type="submit"
            dense
            height="44"
            width="111"
            depressed
            class="search-button size16-weight600"
          >
            {{ $t('search') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>
<script>
import DiningMixins from '@/modules/product/dining/mixins/DiningMixins';
import KeywordSearchComponent from './KeywordSearchComponent.vue';
import SelectedContextFlightMixins from '@/modules/flights/mixins/SelectedContextFlightMixins';
import { mapboxService } from '@/core/services/MapboxService';
import GoogleTagManagerMixins from '@/core/mixins/GoogleTagManagerMixins';

export default {
  name: 'SearchComponent',
  mixins: [DiningMixins, SelectedContextFlightMixins, GoogleTagManagerMixins],
  components: { KeywordSearchComponent },
  props: {
    isMobile: {
      default: false,
      type: Boolean,
    },
    mapState: Boolean,
  },
  data() {
    return {
      selectedItemValue: {
        text: null,
        longLat: null,
        city: null,
      },
      itemFromQuery: {
        q: this.$route.query?.q || '',
        longLat: [
          this.$route.query?.lng || null,
          this.$route.query?.lat || null,
        ],
        city: this.$route.query?.city || null,
      },
      model: null,
      search: null,
      timer: null,
      isActive: false,
      searchQuery: '',
      isOpenSearchTooltip: false,
    };
  },
  async mounted() {
    if (this.$route.query && !this.$route.query.city) {
      await this.autoSearch();
    } else if (this.$route.query.city) {
      await this.updateSelectedCity(this.$route.query.city);
    }
  },
  methods: {
    async getUserLocationAndRedirect() {
      this.$getLocation().then(
        (coordinates) => {
          this.performReverseGeocode({
            lat: coordinates.lat,
            lng: coordinates.lng,
          });
        },
        (rejected) => {
          //Just to catch the rejection so chrome console doesn't complain
        }
      );
    },
    searchCityDebounced(cityName) {
      // cancel pending call
      clearTimeout(this.timer);

      // delay new call 400ms
      this.timer = setTimeout(() => {
        this.performCitySearch(cityName);
      }, 400);
    },
    async onSubmit() {
      if (this.$vuetify.breakpoint.mdAndDown) {
        this.$emit('modalClose');
      }
      if (
        this.$route.name == 'dining-map' &&
        this.$vuetify.breakpoint.mdAndUp
      ) {
        this.$emit('close');
      }
      this.pushDiningSearchButtonClickAnalytics(
        this.selectedItemValue.city
          ? this.selectedItemValue.city
          : this.selectedContextFlight?.destination.city,
        this.selectedItemValue.longLat
          ? this.selectedItemValue.longLat
          : [
              this.selectedContextFlight?.destination.longitude,
              this.selectedContextFlight?.destination.latitude,
            ],
        this.searchQuery
      );
      this.onSubmitQuery();
    },
    onEnter(query) {
      this.searchQuery = query;
      this.onSubmit();
    },
    onSubmitQuery() {
      if (this.selectedItemValue.longLat != null) {
        this.updateSearchQuery(this.$route.params, this.selectedItemValue);
        return;
      } else if (
        this.userLocation.city &&
        !this.selectedContextFlight?.destination.city
      ) {
        this.updateSearchQuery(this.$route.params, {
          city: this.userLocation.city,
          longLat: [this.userLocation.lng, this.userLocation.lat],
        });
        this.updateSelectedCity(this.userLocation.city);
        return;
      } else {
        this.updateSearchQuery(this.$route.params, {
          city: this.selectedContextFlight?.destination.city,
          longLat: [
            this.selectedContextFlight?.destination.longitude,
            this.selectedContextFlight?.destination.latitude,
          ],
        });
      }
    },
    onClearCity() {
      this.selectedItemValue = {
        text: null,
        longLat: null,
        city: null,
      };
    },
    onClearWord() {
      this.searchQuery = '';
    },
    onChange(args) {
      if (args) {
        const { text, city, longLat } = args;
        this.selectedItemValue = {
          text,
          city,
          longLat,
        };
      } else {
        this.selectedItemValue = {
          text: null,
          city: null,
          longLat: null,
        };
      }
    },
    onFormChange(value) {
      this.searchQuery = value;
    },
    updateSearchQuery(params, query) {
      const routeName =
        this.$route.name === 'dining-map' ? 'dining-map' : 'dining-results';
      let queryObject = {};
      if (
        (this.itemFromQuery.q !== this.searchQuery ||
          (!!query && this.itemFromQuery.city !== query.city) ||
          this.selectedItemValue.city !== query.city) &&
        (!this.hasSelectedContextFlight || !this.diningSearch.city)
      ) {
        queryObject.q = this.searchQuery;
        queryObject.city = !!query ? query.city : this.$route.query.city;
        queryObject.lng = !!query ? query.longLat[0] : this.$route.query.lng;
        queryObject.lat = !!query ? query.longLat[1] : this.$route.query.lat;
      } else if (
        this.hasSelectedContextFlight &&
        !this.selectedItemValue.city
      ) {
        this.updateSelectedCity(this.selectedContextFlight?.destination.city);
        queryObject.q = this.searchQuery;
        queryObject.city = this.selectedContextFlight?.destination.city;
        queryObject.lng = this.selectedContextFlight?.destination.longitude;
        queryObject.lat = this.selectedContextFlight?.destination.latitude;
      } else if (this.hasSelectedContextFlight && this.selectedItemValue.city) {
        queryObject.q = this.searchQuery;
        queryObject.city = this.selectedItemValue.city;
        queryObject.lng = this.selectedItemValue.longLat[0];
        queryObject.lat = this.selectedItemValue.longLat[1];
      }
      this.pushToRouterQuery(routeName, params, queryObject);
    },

    pushToRouterQuery(routeName, params, queryObject) {
      //Condition just to catch the rejection so chrome console doesn't complain
      if (JSON.stringify(queryObject) !== JSON.stringify(this.$route.query)) {
        this.$router.replace({
          name: routeName,
          params: params,
          query: queryObject,
        });
      }
    },
    async updateSelectedCity(city) {
      if (this.$route.query.country) {
        city += ` ${this.$route.query.country}`;
      }
      if (city) {
        const res = await mapboxService.getCities(city);
        if (res.features && res.features.length > 0) {
          this.selectedItemValue = {
            city: res.features[0].place_name_en ?? res.features[0].place_name,
            longLat: res.features[0].center,
            text: res.features[0].text_en ?? res.features[0].text,
          };
        }
      }
    },
    async autoSearch() {
      await this.updateSelectedCity(
        this.selectedContextFlight?.destination.city
      );
      this.updateSearchQuery(this.$route.params, this.selectedItemValue);
    },
  },
  watch: {
    ['$route.query']: {
      immediate: true,
      handler(query) {
        if (query && this.selectedItemValue.city) {
          this.itemFromQuery.q = query.q || '';
          this.itemFromQuery.city = query.city || null;
          this.itemFromQuery.longLat = [query.lng || null, query.lat || null];
          if (query.city) {
            this.updateSelectedCity(query.city);
          }
        }
      },
    },
    search(val, oldVal) {
      if ((!val || val === this.selectedItemValue.city) && oldVal) {
        return;
      }
      this.searchCityDebounced(val);
    },
    reverseGeocodeResult(val, oldVal) {
      if (!!val && val !== oldVal && this.selectedItemValue.city) {
        for (let place of val.features) {
          if (place.place_type[0] == 'place') {
            this.updateSearchQuery(this.$route.params, {
              city: place.text,
              longLat: place.center,
            });
          }
        }
      }
    },
    async selectedContextFlight(val, oldVal) {
      if (!!val && val.destination.city !== oldVal?.destination.city) {
        if (!this.$route.query?.q) {
          await this.autoSearch();
        } else {
          this.$emit('showAlert');
        }
      } else if (!val) {
        await this.updateSelectedCity(this.userLocation.city);
        this.updateSearchQuery(this.$route.params, this.selectedItemValue);
        this.isOpenSearchTooltip = true;
      }
    },
    diningSearch: {
      deep: true,
      async handler(newLocation, oldLocation) {
        if (
          newLocation.lat &&
          newLocation.lng &&
          oldLocation?.lat !== newLocation.lat &&
          oldLocation?.lng !== newLocation.lng &&
          !this.selectedItemValue.city &&
          !this.hasSelectedContextFlight
        ) {
          await this.updateSelectedCity(this.userLocation.city);
          this.updateSearchQuery(this.$route.params, this.selectedItemValue);
          this.isOpenSearchTooltip = true;
        }
      },
    },
  },
  computed: {
    selectedCity() {
      return this.selectedItemValue?.city;
    },
    setColsBreackpoints() {
      let cols = 'auto';
      if (this.$vuetify.breakpoint.mdAndDown || this.mapState) {
        cols = '12';
      } else if (this.$vuetify.breakpoint.lgOnly) {
        cols = '5';
      } else if (this.$vuetify.breakpoint.xlOnly) {
        cols = 'auto';
      } else if (this.$vuetify.breakpoint.xsOnly) {
        cols = '11';
      }
      return cols;
    },
    items() {
      if (!this.hasCitySearch || !this.search) {
        return [];
      } else {
        return this.citySearch.result.features.map((result) => ({
          city: result.text,
          longLat: result.center,
          text: result.place_name,
        }));
      }
    },
    reverseGeocodeResult() {
      return this.reverseGeocode.result;
    },
    shouldShowTooltip() {
      return (
        this.isOpenSearchTooltip &&
        ((this.selectedItemValue.text &&
          this.userLocation.city.includes(this.selectedItemValue.text)) ||
          !this.itemFromQuery.city) &&
        !this.hasSelectedContextFlight
      );
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .search-field {
  border-radius: 8px;

  fieldset {
    border: 1px solid #cfd5e0;
  }
}

::v-deep .search-input {
  border-radius: 0;
  border: 1px solid #b8c1d1 !important;
  background: white;
}

.v-input__slot .v-text-field__slot > input {
  font-size: 14px;
}

.search-box {
  width: 100%;
  border-radius: 8px;

  @media (min-width: map-get($grid-breakpoints, lg)) {
    width: 400px;
  }

  &-location {
    &-tooltip {
      position: absolute;
      z-index: 2;
      top: 170px;
      background: #05090f;
      border-radius: 8px;
      color: #fff;
      padding: 7px 14px 7px 11px;
      @include font-size(14, 150);

      &-image {
        margin-left: 14px;
        cursor: pointer;
      }

      &::before {
        content: '';
        position: absolute;
        top: -19px;
        left: 15px;
        width: 10px;
        height: 10px;
        border: 10px solid transparent;
        border-bottom: 10px solid #05090f;
      }
    }
  }

  ::v-deep fieldset {
    border: 1px solid #cfd5e0;
  }
}

::v-deep .search-box-mobile {
  width: 100%;

  &.v-text-field .v-input__append-inner {
    padding: 0;
  }
}

.search-button {
  border-radius: 8px;
}
</style>
